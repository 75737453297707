import React, { FC } from 'react';
import { Link } from 'gatsby';

import { IArticleList } from './models';
import './ArticleList.scss';

const ArticleList: FC<IArticleList> = ({ articles, itemsCount }) => (
  <>
    {articles.slice(0, itemsCount).map(({ title, intro, link }) => (
      <div className="dt-search-result__article-block">
        <strong className="dt-search-result__article-title">
          <Link to={link}>{title}</Link>
        </strong>
        <p className="dt-search-result__article-text">{intro}</p>
      </div>
    ))}
  </>
);

export default ArticleList;
