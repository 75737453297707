import React, { FC, useEffect, useState, useCallback } from 'react';
import { graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import classNames from 'classnames';
import algoliasearch from 'algoliasearch/lite';

import Overlay from 'components/common/Overlay';
import Layout from 'components/Layout';
import ProductList from 'components/ProductList';
import DidYouKnowLoadableWrapper from 'components/FooterPromo/DidYouKnowLoadable';
import ArticleList from 'components/ArticleList';
import Expertise from 'components/Expertise';

import getQueryParamByName from 'utils/getQueryParamByName';
import { isBrowser } from 'utils/browser';
import { gtmService } from 'services/gtmService';

import { ISearchResultPage } from './model.d';

import 'styles/main.scss';
import './SearchPage.scss';

const RESULTS_PREVIEW_COUNT = 4;

const client = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID as string,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY as string
) as any;
const productsIndex = client.initIndex(process.env.GATSBY_ALGOLIA_PRODUCT_INDEX);
const articleIndex = client.initIndex(process.env.GATSBY_ALGOLIA_ARTICLE_INDEX);

const SearchButton = ({ isRendering, text, onClick }) =>
  isRendering ? (
    <div className="btn-holder">
      <Button onClick={onClick} variant="outline-primary" className="dt-btn-mw-208">
        {text}
      </Button>
    </div>
  ) : null;

const SearchResultPage: FC<ISearchResultPage> = ({
  data: {
    searchResults: data,
    relatedArticles,
    articlesTitle: { boldTitle, regularTitle },
    siteSettings,
    homepageSettings,
    menu,
    footerNavigation,
    mobileAppPromoBanner,
    languageSelector,
  },
}) => {
  const {
    allResultsTab,
    articlesTab,
    buttonTextArticles,
    buttonTextProducts,
    productsTab,
    didYouKnow,
    searchText,
    seoNoIndex,
    seoMetaDescription,
    seoMetaKeywords,
    seoMetaTitle,
    seoCanonicalUrl,
    seoExternalHreflangs,
    seoImage,
    ogPageType,
    noResultsText,
    seeLessArticlesText,
    seeLessProductsText,
    link,
  } = data;

  const [searchResults, setSearchResults] = useState<{
    articles: ArticleTypes.IArticle[];
    products: ProductTypes.IProduct[];
    query: string;
  }>({
    articles: [],
    products: [],
    query: '',
  });

  const [activeTab, setActiveTab] = useState<string | null>('all');
  const [isLoading, setIsLoading] = useState(true);

  const [isArticlesExpanded, setArticlesExpanded] = useState<Boolean>(false);
  const [isProductsExpanded, setProductsExpanded] = useState<Boolean>(false);

  const searchQuery = isBrowser() && decodeURI(getQueryParamByName('query') ?? '');
  const filter = { filters: `lang: ${siteSettings.lang}` };

  useEffect(() => {
    let timeoutId;

    if (searchQuery) {
      Promise.all([
        productsIndex.search(searchQuery, filter),
        articleIndex.search(searchQuery, filter),
      ]).then(([productsSearch, articleSearch]) => {
        setSearchResults({
          articles: articleSearch.hits,
          products: productsSearch.hits,
          query: searchQuery,
        });

        timeoutId = gtmService.emitSearchResultsView(searchQuery);
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchQuery]);

  const articleResultsCount = searchResults.articles.length;
  const productResultsCount = searchResults.products.length;
  const totalResultsCount = articleResultsCount + productResultsCount;
  const isShowAllArticlesButton = articleResultsCount > RESULTS_PREVIEW_COUNT;
  const isShowAllProductsButton = productResultsCount > RESULTS_PREVIEW_COUNT;
  const articleListViewedItems = isArticlesExpanded ? articleResultsCount : RESULTS_PREVIEW_COUNT;
  const productsListViewedItems = isProductsExpanded ? productResultsCount : RESULTS_PREVIEW_COUNT;

  const handleExpandArticles = () => {
    setArticlesExpanded((prevState) => !prevState);
    isBrowser() && window.scrollTo(0, 0);
  };

  const handleExpandProducts = () => {
    setProductsExpanded((prevState) => !prevState);
    isBrowser() && window.scrollTo(0, 0);
  };

  const handleMoveToArticlesTab = useCallback(() => {
    setActiveTab('articles');
    setArticlesExpanded(true);
    isBrowser() && window.scrollTo(0, 0);
  }, []);

  const handleMoveToProductsTab = useCallback(() => {
    setActiveTab('products');
    setProductsExpanded(true);
    isBrowser() && window.scrollTo(0, 0);
  }, []);

  const seeAllArticlesButton = (
    <SearchButton
      isRendering={isShowAllArticlesButton}
      text={isArticlesExpanded ? seeLessArticlesText : buttonTextArticles}
      onClick={handleExpandArticles}
    />
  );
  const movetoArticleTabButton = (
    <SearchButton
      isRendering={isShowAllArticlesButton}
      text={buttonTextArticles}
      onClick={handleMoveToArticlesTab}
    />
  );
  const movetoProductsTabButton = (
    <SearchButton
      isRendering={isShowAllProductsButton}
      text={buttonTextProducts}
      onClick={handleMoveToProductsTab}
    />
  );
  const seeAllProductsButton = (
    <SearchButton
      isRendering={isShowAllProductsButton}
      text={isProductsExpanded ? seeLessProductsText : buttonTextProducts}
      onClick={handleExpandProducts}
    />
  );

  return (
    <Layout
      seo={{
        seoNoIndex,
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoCanonicalUrl,
        seoExternalHreflangs,
        seoImage,
        ogPageType,
      }}
      siteSettings={siteSettings}
      menu={menu}
      footerNavigation={footerNavigation}
      mobileAppPromoBanner={mobileAppPromoBanner}
      homepageSettings={homepageSettings}
      languageSelector={languageSelector}
      url={link}
    >
      <Overlay visible={isLoading} />
      <div
        className={classNames('dt-search-result', {
          'dt-search-result--loaded': !isLoading,
        })}
      >
        <section className="dt-search-result__gray-intro-block">
          <Container fluid>
            {!totalResultsCount ? (
              <>
                <h1>
                  <strong className="dt-search-result__page-subtitle">{noResultsText}</strong>
                </h1>
                <div className="dt-search-result__page-title">{`“${searchResults.query}”`}</div>
              </>
            ) : (
              <>
                <strong className="dt-search-result__page-subtitle">{searchText}</strong>
                <h1 className="dt-search-result__page-title">{`“${searchResults.query}”`}</h1>
              </>
            )}
          </Container>
        </section>
        <Container fluid>
          <div className="dt-tabs">
            {totalResultsCount ? (
              <Tabs
                activeKey={activeTab}
                transition={false}
                className="dt-tabs__nav"
                onSelect={(tab) => setActiveTab(tab)}
              >
                <Tab
                  eventKey="all"
                  title={
                    <>
                      {allResultsTab}
                      <span className="dt-tabs__amount-item">{` (${totalResultsCount})`}</span>
                    </>
                  }
                >
                  <div className="dt-search-result__product-results ">
                    {productResultsCount > 0 ? (
                      <ProductList
                        showRating={!!siteSettings?.useBV}
                        products={searchResults.products.slice(0, RESULTS_PREVIEW_COUNT)}
                        btn={null}
                      />
                    ) : null}
                    {movetoProductsTabButton}
                  </div>
                  <Row>
                    <Col lg={8}>
                      <div className="dt-search-result__article-results">
                        {articleResultsCount > 0 ? (
                          <ArticleList
                            articles={searchResults.articles}
                            itemsCount={articleListViewedItems}
                          />
                        ) : null}
                        {movetoArticleTabButton}
                      </div>
                    </Col>
                  </Row>
                </Tab>
                <Tab
                  eventKey="products"
                  title={
                    <>
                      {productsTab}
                      <span className="dt-tabs__amount-item">{` (${productResultsCount})`}</span>
                    </>
                  }
                >
                  <div className="dt-tabs__all-products">
                    {productResultsCount > 0 ? (
                      <ProductList
                        showRating={!!siteSettings?.useBV}
                        products={searchResults.products.slice(0, productsListViewedItems)}
                        btn={null}
                      />
                    ) : (
                      <p>{noResultsText}</p>
                    )}
                  </div>
                  {seeAllProductsButton}
                </Tab>
                <Tab
                  eventKey="articles"
                  title={
                    <>
                      {articlesTab}
                      <span className="dt-tabs__amount-item">{` (${articleResultsCount})`}</span>
                    </>
                  }
                >
                  <Row>
                    <Col lg={8}>
                      {articleResultsCount > 0 ? (
                        <ArticleList
                          articles={searchResults.articles}
                          itemsCount={articleListViewedItems}
                        />
                      ) : (
                        <p>{noResultsText}</p>
                      )}
                      {seeAllArticlesButton}
                    </Col>
                  </Row>
                </Tab>
              </Tabs>
            ) : (
              <Expertise
                title={{ boldText: boldTitle, regularText: regularTitle }}
                articles={relatedArticles.nodes}
              />
            )}
          </div>
        </Container>
        <DidYouKnowLoadableWrapper didYouKnow={didYouKnow} mask swap />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query SearchPageQuery($lang: String) {
    languageSelector(lang: { eq: $lang }) {
      ...FragmentLanguageSwitcher
    }
    siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
      useBV
      lang
    }
    menu(lang: { eq: $lang }) {
      ...FragmentHeader
    }
    footerNavigation(lang: { eq: $lang }) {
      ...FragmentFooter
    }
    mobileAppPromoBanner(lang: { eq: $lang }) {
      ...FragmentMobileAppPromoBanner
    }
    homepageSettings(lang: { eq: $lang }) {
      ...FragmentHomepageSettings
    }
    articlesTitle: homepageBlocks(blockType: { eq: "Articles carousel" }, lang: { eq: $lang }) {
      boldTitle
      regularTitle
    }
    searchResults(lang: { eq: $lang }) {
      link
      allResultsTab
      articlesTab
      buttonTextArticles
      buttonTextProducts
      seeLessArticlesText
      seeLessProductsText
      noResultsText
      productsTab
      searchText
      seoMetaDescription
      seoMetaKeywords
      seoNoIndex
      seoMetaTitle
      seoCanonicalUrl
      seoImage
      ogPageType
      seoExternalHreflangs {
        key
        value
      }
      tags
      title
      didYouKnow {
        labelText
        descriptionText
        buttonText
        ariaLabel
        imageAlt
        labelText
        didYouKnowBG {
          fluid {
            srcSet
            base64
          }
          fallbackUrl
        }
        buttonURL {
          url
          icon
        }
      }
    }
    relatedArticles: allUmbracoArticle(limit: 15, filter: { lang: { eq: $lang } }) {
      nodes {
        title
        link
        intro
        label
        image {
          fallbackUrl
          fluid {
            srcSet
            base64
          }
        }
      }
    }
  }
`;

export default SearchResultPage;
